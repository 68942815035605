import { Login, Person, useIsSignedIn } from '@microsoft/mgt-react';
import React, { useEffect, useState } from 'react';
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

import './App.css';
import Office from './components/Office';

//const applicationId = "8da33151-e89d-4325-ad2c-d18a116d8a59"; //ローカル
//const applicationId = "212accfb-6903-4bfe-aa26-acaf74ecac4c"; //検証公開
//const applicationId = "d5240759-65e1-4a86-ad24-358c401ed6e9"; //本番公開
//const applicationId = "3f2bff54-08b5-4281-9f06-93dbcf825f15";
const applicationId = "d2a38e07-a3a3-43ef-bfc0-fa8badf4af84";

Providers.globalProvider = new Msal2Provider({
  clientId: applicationId,
  authority: "https://login.microsoftonline.com/organizations/", 
  scopes: [ 'user.read', 'openid', 'profile', 'people.read', 'user.readbasic.all', 'Presence.Read', 'Presence.ReadWrite', 'Presence.Read.All', 'GroupMember.Read.All', 'Directory.Read.All', 'Group.Read.All', 'Mail.Read', 'Calendars.Read', 'Calendars.Read.Shared', 'Calendars.ReadWrite', 'Directory.AccessAsUser.All', 'Calendars.ReadWrite.Shared', 'Mail.Send.Shared', 'Sites.ReadWrite.All' ],
  isMultiAccountEnabled: false,
});


function App() {
  const [isSignedIn] = useIsSignedIn();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    if (isSignedIn) {
      getUserDetails();
    }
  }, [isSignedIn]);

  const getUserDetails = async () => {
    try {
      const provider = Providers.globalProvider;
      if (provider && provider.state === ProviderState.SignedIn) {
        const graphClient = provider.graph.client;

        // /me エンドポイントからユーザー情報を取得
        const user = await graphClient.api('/me').get();
        setUserDetails(user);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };


  return (
    <div>
      {isSignedIn ? (
        <div>
          <Office user={userDetails}/>
        </div>
      ) : (
        <div className="signin-container">
          <div>Welcome to SEKITORI-KUN.</div>
          <div>Please sign in to continue.</div>
          <Login className="login-button" />
        </div>
      )}
      {isSignedIn && (
        <div className="avatar-icon custom-location-avatar">
          <Login>
            <CustomSignedInTemplate template="signed-in-button-content" />
            <CustomCommandTemplate template="flyout-commands" />
          </Login>
        </div>
      )}
    </div>
  );
}

const CustomSignedInTemplate = ({ dataContext }) => {
  const { personDetails, personImage } = dataContext;

  return (
    <div>
      <Person className="person" personDetails={personDetails} showPresence={false} personImage={personImage} />
    </div>
  );
};

const CustomCommandTemplate = ({ dataContext }) => {
  const { handleSignOut } = dataContext;

  const buttonStyle = {
    padding: '8px 10px',
    borderRadius: '5px',
    backgroundColor: '#175FC7',
    color: 'white',
    border: 'none',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    outline: 'none',
  };

  const handleHover = (event) => {
    event.target.style.backgroundColor = '#134EB2';
  };

  const handleLeave = (event) => {
    event.target.style.backgroundColor = '#175FC7';
  };

  return (
    <div>
      <button
        style={buttonStyle}
        onClick={handleSignOut}
        onMouseEnter={handleHover}
        onMouseLeave={handleLeave}
      >
        Sign Out
      </button>
    </div>
  );
};

export default App;
